<script>
export default {
  components: { },
  data() {
    return {
      search: this.$parent.$parent.search
    };
  },
  methods: {
    getSearch: function () {
      if (this.search != this.$parent.$parent.$route.query.q) {
        this.$router.push({
          'path': '/course/search',
          'query': {
            'q': this.search
          }
        })
      }
    },
  }
};
</script>

<template>
  <div style="margin-top:-7px; margin-left: -7px; margin-right: -7px;">
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="getSearch">
          <b-input-group label-for="search">
            <b-form-input class="border-secondary" v-model="search" type="text"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="search == $parent.$parent.$route.query.q" type="submit" variant="secondary" class="bg-white">
                <i class="bx bx-search align-middle font-size-20 text-secondary"></i>
              </b-button>
              <router-link :to="'/course'" class="text-dark">
                <button class="btn btn-danger ml-1">Limpar</button>
              </router-link>
            </b-input-group-append>
          </b-input-group>
        </form>
      </div>
    </div>
  </div>
</template>


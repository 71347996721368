<script>
import Layout from "../../layouts/course";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      'Courses': 'Cursos',
      'Files': 'Arquivos',
    },
    es: {
      'Courses': 'Cursos',
      'Files': 'Arquivos',
    }
  },
  components: { Layout },
  data() {
    return {
      pagination: '',

      course: {
        id: this.$route.params.id,
        title: '',
        step: {
          title: '',
          banner: '',
          video: '',
          description: '',
          number: '',
          status: false,
          files: '',
          prev: '',
          next: '',
        }
      },
    };
  },
  methods: {
    getCourse() {
      this.course.step.title  = ''
      this.course.step.number = ''
      this.course.step.banner = ''
      this.course.step.video  = ''
      this.course.step.description = ''
      this.course.step.prev = ''
      this.course.step.next = ''

      api
        .get('course/steps/' + this.course.id + '/' + this.pagination)
        .then(response => {
          this.course.status = true
          if (response.data.status == 'success') {
            this.course.title = response.data.course.title,
            this.course.step.title  = response.data.course.step.title,
            this.course.step.number = response.data.course.step.order,
            this.course.step.banner = response.data.course.step.banner,
            this.course.step.video  = response.data.course.step.video,
            this.course.step.description = response.data.course.step.description,
            this.course.step.prev = response.data.course.step.pagination.prev,
            this.course.step.next = response.data.course.step.pagination.next

            this.course.status = true
            this.getFile()
          } else if (response.data.status == 'restrict') {
            this.$router.push('/account/subscriptions');
          } else {
            this.course.status = false
          }
        })
        .catch(error => {
          this.course.status = false
          this.errored = error
        })
    },
    getFile() {
      api
        .get('course/file/' + this.course.id)
        .then(response=> {
          this.course.files = response.data.list
        })
    }
  },
  mounted() {
    if (this.$route.params.pagination) {
      this.pagination = this.$route.params.pagination
    }

    this.getCourse()
  },
  watch: {
    '$route.params.pagination': function(v) {
      if (v) {
        this.pagination = v
        this.getCourse()
      }
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18 notranslate">{{ course.title }}</h4>
        </div>
      </div>
    </div>
    <div v-if="course.status">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">
            <h5 class="card-title notranslate">{{ course.step.title }}</h5>
          </h4>
        </div>
      </div>
      <div class="card bg-dark">
        <div class="card-body p-0" v-if="course.step.video.code">
          <iframe v-if="course.step.video.type == 'vimeo'" class="d-block m-auto step-video" :src="'https://player.vimeo.com/video/' + course.step.video.code +'?color=ffffff'" style="max-width: 100%" width="700" height="400" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
          <iframe v-else class="d-block m-auto step-video" height="400" width="700" :src="'https://www.youtube.com/embed/'+course.step.video.code+''" frameborder="0"  style="max-width: 100%"  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div v-else>
          <img class="d-block mx-auto" :src="course.step.banner" alt="">
        </div>
      </div>
      <div class="card" v-if="course.step.description">
        <div class="card-body notranslate">
          <span v-html="course.step.description"></span>
        </div>
      </div>
      <div v-if="course.files">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">{{ t('Files') }}</h4>
            <br>
            <div v-for="(file, index) in course.files" :key="index">
              <div class="d-flex align-items-center justify-content-between">
                <p class="m-0">{{ file.title }}</p>
                <a class="btn btn-success btn-sm m-1" target="_blank" :href="file.content">Download</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center">
        <router-link v-if="course.step.prev" class="btn btn-danger btn-lg mr-2 pr-4" :to="'/course/'+course.id+'/'+course.step.prev">
          <i class="bx bx-chevron-left align-middle" style="font-size:1.8rem;"></i>
          Anterior
        </router-link>
        <router-link v-else class="btn btn-danger btn-lg mr-2 disabled pr-4" :to="'/course/'+course.id">
          <i class="bx bx-chevron-left align-middle" style="font-size:1.8rem;"></i>
          Anterior
        </router-link>

        <router-link v-if="course.step.next" class="btn btn-danger btn-lg pl-4" :to="'/course/'+course.id+'/'+course.step.next">
          Próximo
          <i class="bx bx-chevron-right align-middle" style="font-size:1.8rem;"></i>
        </router-link>
        <router-link v-else class="btn btn-danger btn-lg disabled pl-4" :to="'/course/'+course.id">
          Próximo
          <i class="bx bx-chevron-right align-middle" style="font-size:1.8rem;"></i>
        </router-link>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>